import {classNames, currencyFormatter} from "../Common/Helper";
import PreloaderHead from "../Common/PreloaderHead";

export default function Items({preloader, data, opportunity, totalVolume = null}) {

    const currentTotalVolume = totalVolume?.toLocaleString() ?? Math.ceil(data?.volume) 

    return preloader ? <PreloaderHead/> : (
        <div className="relative mt-4 flex items-center lg:justify-center lg:justify-between">
            <div className={classNames(opportunity ? 'md:grid-cols-6' : 'lg:grid-cols-6', "overflow-hidden rounded-lg bg-white shadow text-sm grid grid-cols-2 gap-6 lg:flex lg:justify-between w-full px-6 py-4 ")}>
                {!opportunity ? (
                    <div className="flex flex-col">
                        <span className="font-semibold text-[1rem] text-gray-600 mb-1">Umzug Nr.</span>
                        <span>{data?.relocationShortId}</span>
                    </div>
                ): (
                    <div className="flex flex-col">
                        <span className="font-semibold text-[1rem] text-gray-600 mb-1">Anfrage Nr.</span>
                        <span>{data?.opportunityShortId}</span>
                    </div>
                )}

                {!opportunity ? (
                    <div className="flex flex-col">
                        <span className="font-semibold text-[1rem] text-gray-600 mb-1">Gebote</span>
                        <span>{data?.numberOfBids}</span>
                    </div>
                ): (
                    <div className="flex flex-col">
                        <span className="font-semibold text-[1rem] text-gray-600 mb-1">Auslieferungen</span>
                        <span>{data?.numOfDeliveries}</span>
                    </div>
                )}
                
                {!opportunity && (<div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">&empty;-Gebot</span>
                    <span>{currencyFormatter.format(data?.averageOffer) || currencyFormatter.format(0)}</span>
                </div>)}


                {!opportunity && (<div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">Auktion endet am</span>
                    <span>{new Date(data?.relocationAuctionEndData).toLocaleDateString("de-DE", {day:"2-digit", month:"2-digit", year: "numeric"})} 13:00 Uhr</span>
                </div>)}

                <div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">Umzugstermin</span>
                    <div>
                        <span>{new Date(data?.relocationDateEarliest).toLocaleDateString("de-DE", {weekday:"short",day:"2-digit", month:"2-digit", year: "numeric"})}</span>
                        {data?.relocationDateLatest && data?.relocationDateLatest != data?.relocationDateEarliest && <span> bis {new Date(data?.relocationDateLatest).toLocaleDateString("de-DE", {weekday:"short",day:"2-digit", month:"2-digit", year: "numeric"})}</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">Von / Nach</span>
                    <span>{data?.loadingCity} / {data?.unloadingCity}</span>
                </div>

                <div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">Volumen</span>
                    <span className={currentTotalVolume == parseInt(data?.volume) ? '' :'text-accent-1'}>{data?.volume? currentTotalVolume : "_TODO_BACKEND"} m<sup>3</sup></span>
                </div>
                <div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">Belade Wohnfläche</span>
                    <span>{data?.loadingSurface} m<sup>2</sup></span>
                </div>
                <div className="flex flex-col">
                    <span className="font-semibold text-[1rem] text-gray-600 mb-1">Zusatzleistungen</span>
                    <span>{data?.services === 1 ? 'Ja' : 'Nein'}</span>
                </div>
            </div>
        </div>
    )
}